.landing-content {
    position: relative;
}

.main-image {
    margin-top: 0; /* Просто пример для отступа */
}

.scroll-image {
    position: fixed;
}

.scroll-image img {
    width: 100px; /* Размер второй картинки */
    max-width: 100%;
}

.scroll-image.visible {
    opacity: 1; /* Показываем вторую картинку */
}

.section {
    padding: 50px 0;
}

.image-container {
    position: relative;
    max-width: 100%; /* Ограничение размеров изображений */
    overflow: hidden; /* Скрытие обрезанных частей изображений */
}

.image-container img {
    width: 100%; /* Сохранение соотношения сторон изображений */
    height: auto; /* Автоматическое определение высоты */
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    text-align: center;
}

.overlay h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

.overlay p {
    font-size: 16px;
}

