.section--wwd .app-container {
    width: 60%;
    height: 320px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: Arial, sans-serif;
    transition: .5s all;
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 20px;
}

.section--wwd .app-container .tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.section--wwd .tag {
    cursor: pointer;
    background-color: #B8D5CD;
    color: #000;
    padding: 10px 25px;
    margin: 5px 5px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    transition: .5s ease;
}

.section--wwd .tag:hover {
    background-color: #00ae83;
}


.section--wwd .description-container {
    width: 100%;
    margin: 20px 0;
}


.section--wwd .description-container p {
    font-size: 20px;
    color: #333;
    margin: 0;
    border: 2px solid #b5b3b3;
    padding: 20px;
    border-radius: 8px;
}

.section--wwd .develop-button {
    background-color: transparent;
    width: 100%;
    color: #1d875d;
    padding: 10px 20px;
    border: 1px solid #1d875d;
    border-radius: 10px;
    cursor: pointer;
    font-size: 25px;
    transition: .3s ease;
}

.section--wwd .develop-button:hover {
    background-color: #45a049;
    color: #fff;
}
