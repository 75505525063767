.section--feedback .timeline-event {
    display: flex;
    flex-direction: row;
    height: 300px;
    width: 550px;
    padding: 10px;
    transition: opacity 1s ease-out;
}


.timeline-event.slide-in {
    animation: TimeLine-slide-In 0.5s ease forwards;
}

@keyframes TimeLine-slide-In {
    from {
        transform: translateX(-200px);
    }
    to {
        transform: translateX(0);
    }
}

.section--feedback .timeline-event .event-content {
    padding: 10px;
}

.section--feedback .tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
}

.section--feedback .tag {
    cursor: pointer;
    background-color: white;
    border: 1px solid #333333;
    color: #000;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: bold;
    transition: .3s ease;
}

.section--feedback .tag:hover {
    background-color: #3e3e3e;
    color: white;
}

.section--feedback .event-content .info {
    border: 1px solid #c0c0c0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-top: 10px;
    padding-inline: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 1s ease-out;

}

.section--feedback .author {
    font-weight: bold;
    margin-top: 10px;
}

.section--feedback .timeline-event .boxes {
    width: 100px;
}

.section--feedback .timeline-event .boxes a {
    height: 30%;
    width: 120px;
    margin-bottom: 20px;
}

.section--feedback .timeline-event .boxes button {
    border-radius: 8px;
    cursor: pointer;
    height: 120px;
    width: 120px;
    background-color: #ffffff;
    background-image: url("../../../assets/arrow-right-feedback.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .5s ease;
}

.section--feedback .timeline-event .boxes button:hover {
    transform: scale(1.02);
}

.section--feedback .view-project {
    display: flex;
    margin-top: 10px;
    padding: 5px 5px;
    background: #ECF5F2;
    color: black;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    align-items: center;
    border: 1px solid #5B5567;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s ease;
    transform: rotate(-5deg);
}

.section--feedback .view-project:hover {
    background-color: #d8ffc4;
}

.section--feedback .event-content .description {
    font-size: 23px;
    color: #3D3B49;
}

