
.navbar {
    position: fixed;
    width: 95%;
    margin-top: 30px;
    z-index: 1000;
    background-color: rgba(56, 52, 62, 0.45); /* Цвет с прозрачностью 5% */
    border: 1px solid #C2BDCB;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-item {
    margin-right: 15px;
}

.nav-text {
    text-decoration: none;
    color: #F0EDF6; /* цвет текста */
    /*color: #fff; !* цвет текста *!*/
    font-weight: 500;
    font-size: 20px; /* увеличиваем размер шрифта */
}

.nav-link {
    transition: .5s ease;
}

.nav-link:hover {
    /* Цвет текста при наведении */
    transform: scale(1.1);
}

.nav-link:hover .nav-text {
    color: white;
}

.nav-item-left {
    display: flex;
    justify-content: space-between;
    width: 17%;
    margin-left: 20px;
}

.nav-item-right {
    display: flex;
    justify-content: space-between;
    width: 17%;
    margin-right: 20px;
}

.center-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: transparent;
}

.center-link:hover {
    transform: scale(1);
}
