.section--landing {
    width: 100%;
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 60%;
    left: 50%;
    margin-top: 0;
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay-first-page h2 {
    display: block;
    margin: 0;
    padding: 10px 20px;
    background-color: transparent; /* Прозрачный белый цвет фона */
    border-radius: 20px;
    color: transparent;
    font-weight: 500;
    position: relative;
    opacity: 0;
    bottom: -50px;
}

.overlay-first-page h2:nth-child(2) {
    animation-delay: 0.2s;
}

.overlay-first-page h2:nth-child(3) {
    animation-delay: 0.5s;
}

.overlay-first-page h2:nth-child(4) {
    animation-delay: 0.8s;
}

.overlay-first-page h2:nth-child(5) {
    animation-delay: 0.9s;
}

.overlay-first-page h2:nth-child(6) {
    animation-delay: 1.2s;
}

.overlay-first-page h2:nth-child(7) {
    animation-delay: 1.5s;
}

.overlay-first-page .move-fp-1 {
    font-size: 112px;
    animation: fadeInUp1 1s ease forwards;
}


@keyframes fadeInUp1 {
    from {
        opacity: 0;
        color: white;
        transform: translate(0%, -50%) translateX(-25px) translateY(-110px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to bottom, white 40%, black 90%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(-25px) translateY(-30px);
    }
}

.overlay-first-page .move-fp-2 {
    font-size: 112px;
    left: -50px;
    animation: fadeInUp2 1s ease forwards;
}



@keyframes fadeInUp2 {
    from {
        opacity: 0;
        color: white;
        transform: translate(-50%, 0) translateX(-250px) translateY(-110px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to bottom, white 0%, white 60%, black 80%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(-60px) translateY(-110px);
    }
}

.move-fp-block-1 {
    display: flex;
    width: 100%;
    margin-top: -120px;
    margin-left: -230px;
    margin-bottom: -120px;
    justify-content: center;
    align-items: center;
}

.move-fp-block-1 h2 {
    display: flex;
    font-size: 100px;
    font-weight: 400;
    top: 0px;
    justify-content: center;
    align-items: center;

}

.overlay-first-page .move-fp-3 {
    animation: fadeInUp3 1s ease forwards;
}

@keyframes fadeInUp3 {
    from {
        opacity: 0;
        transform: translate(0, 0) translateX(-400px) translateY(0px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to bottom, #e6e6e6 0%, #e6e6e6 50%, #e6e6e6 100%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(0px) translateY(0px);
    }
}

.overlay-first-page .move-fp-4 {
    animation: fadeInUp4 1s ease forwards;
    font-weight: 500;
}

@keyframes fadeInUp4 {
    from {
        opacity: 0;
        transform: translate(0, 0) translateX(0px) translateY(600px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to bottom, white 0%, white 50%, white 100%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(0px) translateY(0px);
    }
}

.overlay-first-page .move-fp-5 {
    animation: fadeInUp5 1s ease forwards;
}

@keyframes fadeInUp5 {
    from {
        opacity: 0;
        transform: translate(0, 0) translateX(500px) translateY(0px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to bottom, #e6e6e6 0%, #e6e6e6 50%, #e6e6e6 100%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(0px) translateY(0px);
    }
}

.overlay-first-page .move-fp-6 {
    animation: fadeInUp6 1s ease forwards;
    font-size: 112px;
    z-index: -1;
    margin-top: -100px;
}

@keyframes fadeInUp6 {
    from {
        opacity: 0;
        transform: translate(0, 0) translateX(-600px) translateY(0px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to top, white 0%, white 40%, black 65%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(-110px) translateY(0px);
    }
}

.overlay-first-page .move-fp-7 {
    animation: fadeInUp7 1s ease forwards;
    font-size: 112px;
    z-index: -2;
    margin-top: -80px;
}

@keyframes fadeInUp7 {
    from {
        opacity: 0;
        transform: translate(0, 0) translateX(-210px) translateY(480px);
    }
    to {
        opacity: 1;
        color: transparent;
        background: linear-gradient(to top, white 40%, black 80%); /* Градиент от черного к белому */
        -webkit-background-clip: text; /* Применяем градиент к тексту */
        background-clip: text;
        transform: translate(0, 0) translateX(-200px) translateY(0px);
    }
}

.start-project-btn:hover {
    transform: scale(1.05);
}