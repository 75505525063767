.navigation-button {
    display: flex;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    justify-content: center;
    background-color: transparent;
}

.navigation-button:disabled {
    cursor: pointer;
    opacity: 0.3;
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.navigation-button:enabled {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.navigation-button:hover:not(:disabled) {
    transform: translateY(-2px); /* Slightly raise the button on hover */
}

.navigation-button img {
    max-width: 100%;
}

.navigation-button.next img {
    transform: rotate(0deg);
}

.navigation-button img {
    vertical-align: middle; /* Align the arrow image vertically */
    margin-left: 10px; /* Add some space between the text and the image */
}

.navigation-button.prev img {
    transform: rotate(180deg); /* Rotate the arrow for the previous button */
}
