.tech-wwd {
    text-align: center;
    background-color: #d3e5e5;
    color: #333;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets/TeamTechWhatWeDo.svg");
    background-size: 100%;
}

.tech-wwd .content {
    width: 80%;
    position: relative;
}

.tech-wwd .content h1 {
    font-size: 60px;
    width: 70%;
    font-weight: 400;
}

.tech-wwd .content .target {
    font-weight: bold;
}

.tech-wwd .content .team-tech {
    display: block;
    justify-content: center;
    margin-top: 20px;
}

.tech-wwd .content .team, .tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px 50px;
}

.tech-wwd .content .circle {
    width: 250px;
    height: 250px;
    border: 1px solid #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 400;
    margin-bottom: 10px;
}

.tech-wwd .content .labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.tech-wwd .content .label {
    background-color: transparent;
    padding: 7px 25px;
    margin: 5px;
    border-radius: 5px;
    font-size: 24px;
    border: 1px solid #5B5567;
}

.tech-wwd .content {

}

.tech-wwd .content .left-text, .right-text {
    width: 20%;
    position: absolute;
    top: 50%;
    font-size: 24px;
    text-align: left;
    transform: translateY(-50%);
}

.tech-wwd .content .left-text {
    left: 0;
}

.tech-wwd .content .right-text {
    right: 0;
}


.tech-wwd .content .cross {
    position: absolute;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cross .vertical, .cross .horizontal {
    position: absolute;
    background-color: #333;
}

.tech-wwd .content .cross .vertical {
    width: 10px;
    height: 50px;
}

.tech-wwd .content .cross .horizontal {
    width: 50px;
    height: 10px;
}

.tech-wwd .content .team {
    animation-name: slide-in-left;
}


.tech-wwd .content .tech {
    animation-name: slide-in-right;
}

.tech-wwd .content .labels {
    margin-top: 10px;
}

.tech-wwd .content .labels .label{
    transition: .3s ease;
}

.tech-wwd .content .labels .lb-1 {
    transform: rotate(-10deg) translateY(-50px);
}

.tech-wwd .content .labels .lb-2 {
    transform: rotate(10deg) translateY(-30px);
}

.tech-wwd .content .labels .lb-3{
    transform: rotate(20deg) translateY(-20px);
}

.tech-wwd .content .labels .lb-5 {
    transform: rotate(-10deg) translateY(-40px);
}

.tech-wwd .content .labels .lb-4 {
    transform: rotate(4deg) translateY(-10px);
}

.tech-wwd .content .labels .lb-6 {
    transform: rotate(-3deg) translateY(-50px);
}
