.contact {
    display: block;
    justify-content: center;
    align-items: center;
    height: 80vh;
    border-top: 2px dashed #333;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
}

.contact .content {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    justify-content: center;
}

.contact .footer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.contact .footer div {
    width: 90%;
    display: flex;
}

.contact .footer div .policy {
    display: flex;
    justify-content: flex-start;
}

.policy a {
    color: #5CA040;
}

.contact .footer div .year {
    display: flex;
    justify-content: flex-end;
}

.contact .footer div .year .detail{
    width: 200px;
    display: flex;
    flex-direction: column;
}

.contact .footer div .year .detail span{
    margin-top: 5px;
}

.contact .content h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.contact-container {
    width: 70%;
}

.contact .content .contact-info {
    display: flex;
    justify-content: space-between;
}

.contact .content .contact-info .contact-info-icon {
    display: flex;
    justify-content: center;
    width: 20%;
}

.contact .content .contact-info .contact-info-icon img {
    max-width: 80%;
    height: fit-content;
    border-radius: 50%;
    border: 2px solid #919191   ;
}


.contact .content .message {
    position: relative; /* Добавляем относительное позиционирование, чтобы псевдоэлемент был позиционирован относительно этого блока */
    display: block;
    width: 55%;
    border: 1px solid #919191;
    border-radius: 10px;
    text-align: left;
    padding-inline: 20px;
}

.contact .content .message::after {
    content: "";
    position: absolute;
    bottom: 30px; /* Сдвигаем треугольник вверх, чтобы он был виден */
    left: -75px; /* Оставляем треугольник слева */
    border: 25px solid transparent;
    border-right: 50px solid #919191;
    z-index: -1;
}

.contact .content .message::before {
    content: "";
    position: absolute;
    bottom: 30px; /* Сдвигаем треугольник вверх, чтобы он был виден */
    left: -73px; /* Оставляем треугольник слева */
    border: 25px solid transparent;
    border-right: 50px solid #ffffff;
    z-index: 0;
}

.contact .content .message p {
    font-size: 35px;
    color: #3D3B49;
}

.contact .content .message .ceo-aftername {
    font-size: 25px;
    font-weight: 500;
}

.contact .content .contact-details {
    display: flex;
    width: 20%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.contact .content .contact-details div {
    display: block;
    width: 100%;
    border: 1px solid #919191;
    border-radius: 8px;
    text-align: left;
    padding-inline: 10px;
    font-size: 20px;
}

.contact .content .contact-details a:hover {
    text-decoration: underline;
}

.contact .content .contact-details a {
    text-decoration: none;
    color: #45414D;
    font-weight: 600;
}

.contact .content .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.content .buttons div {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.contact .content button {
    height: 60px;
    width: 100%;
    margin: 5px;
    padding: 10px 20px;
    font-size: 25px;
    font-weight: 600;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.contact .content .brief-button {
    background-color: #5CA040;
    color: white;
    font-size: 30px;
}

.contact .content .contact-button {
    background-color: #ffffff;
    color: #5CA040;
    font-size: 30px;
    border: 1px solid #5CA040;
}

.contact .content button {
    transition: .5s ease;
    scale: 98%;
}

.contact .content button:hover {
    opacity: 0.8;
    scale: 100%;
}


