.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup .popup-inner {
    background: url('../../../assets/PopupBg.jpeg') no-repeat center center/cover;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    max-height: 700px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
}

.popup .popup-inner .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 70px;
    height: 70px;
    background: none;
    border: 1px solid #C2BDCB;
    border-radius: 5px;
    font-size: 60px;
    cursor: pointer;
    color: white;
    transition: .5s;
}

.popup .popup-inner .close-btn:hover {
    transform: scale(1.05);
}

.popup .popup-inner .contact-header {
    display: flex;
    justify-content: left;
    justify-items: center;
    align-items: center;
    width: 100%;
    color: white;
    margin-top: 50px;
    margin-bottom: 30px;
    height: 100%;
}

.popup .popup-inner .header {
    font-weight: 400;
    width: 45%;
    font-size: 90px;
    align-items: center;
    margin: 0;

}

.popup .popup-inner .pre-header {
    font-weight: 300;
    width: 30%;
    font-size: 25px;
    align-items: center;
}

.popup .popup-inner .contact-form {
    display: flex;
    justify-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
}

.popup .popup-inner form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
}

.popup .popup-inner .form-group {
    display: flex;
    flex-direction: column;
}


.popup .popup-inner .form-group-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.popup .popup-inner .form-group-inline .form-group-short {
    width: 30%;
}

.popup .popup-inner .form-group-inline .form-group-short input {
    margin-top: 5px;
}

.popup .popup-inner .form-group label {
    color: #a8d5ba;
    font-size: 17px;
    margin-bottom: 5px;
}

.popup .popup-inner .form-group input,
.popup .popup-inner .form-group textarea {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}


.popup .popup-inner .form-group textarea {
    min-height: 150px;
    max-height: 250px;
    min-width: 100%;
    max-width: 100%;
}

.popup .popup-inner .form-group input::placeholder,
.popup .popup-inner .form-group textarea::placeholder {
    color: #6c757d;
    opacity: 1;
}

.popup .popup-inner textarea {
    height: 100px;
}

.popup .popup-inner .checkbox-group {
    display: flex;
    align-items: center;
}

.popup .popup-inner .checkbox-group input {
    margin-right: 10px;
}

.popup .popup-inner .checkbox-group label {
    color: white;
    font-size: 14px;
}

.popup .popup-inner .form-buttons {
    display: flex;
    justify-content: space-between;
}

.popup .popup-inner .send-btn, .popup .popup-inner .brief-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    transition: .5s;
}

.popup .popup-inner .brief-btn {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.popup .popup-inner .send-btn:hover, .brief-btn:hover {
    background-color: #218838;
}

.popup .popup-inner .brief-btn:hover {
    background-color: white;
    color: #218838;
}

.popup .popup-inner .form-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 50px;
}

.form-footer .checkbox-group {
    width: 40%;
    height: 100%;
}

.form-footer .checkbox-group label{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-footer .checkbox-group input {
    width: 20px;
    height: 20px;
    background: transparent;
}

.form-footer .form-buttons {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.form-footer .form-buttons button {
    border-radius: 10px;
}


.form-footer .form-buttons .send-btn{
    position: relative;
    right: -20px;
    width: 60%;
    margin-left: 50px;
}

.form-footer .form-buttons .brief-btn{
    width: 40%;
    margin-right: 0;
}
